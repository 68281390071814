/* 
  CSS for Its more fun to compute sample player
  Rob Watson, 2020 
*/

.console {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: yellow;
}

body {
  background-color: yellow;
  font-family: 'Orbitron', sans-serif;
}

header {
  text-align: center;
  font-family: 'Orbitron', sans-serif;
  font-size: large;
}

footer {
  text-align: center;
  font-family: 'Orbitron', sans-serif;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: yellow;
  gap: 20px;
  padding: 50px;
}

.gridbox {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 80px;
  font-size: 30px;
  text-align: center;
  font-family: 'Orbitron', sans-serif;
}
